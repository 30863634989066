import {Swiper, Navigation, Thumbs, EffectFade} from "swiper";

$(function () {
    new Swiper('.uq-slider', {
        modules: [Navigation],
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        slidesPerView: 1.1,
        spaceBetween: 20,
        speed: 500,
        grabCursor: true,
        breakpoints: {
            375: {
                slidesPerView: 1.2,
                spaceBetween: 20
            },
            768: {
                slidesPerView: 1.8,
                spaceBetween: 20
            },
            992: {
                slidesPerView: 2.2,
                spaceBetween: 20
            }
        }
    });

    new Swiper('#related-products-slider', {
        modules: [Navigation],
        navigation: {
            nextEl: ".related-products-slider-button-next",
            prevEl: ".related-products-slider-button-prev",
        },
        spaceBetween: 32,
        slidesPerView: 3,
        speed: 500,
        grabCursor: true,
        breakpoints: {
            0: {
                slidesPerView: 1,
                spaceBetween: 16
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 24
            },
            992: {
                slidesPerView: 3,
                spaceBetween: 32
            }
        }
    });

    new Swiper('#related-product-slider', {
        modules: [Navigation],
        navigation: {
            nextEl: ".related-product-slider-button-next",
            prevEl: ".related-product-slider-button-prev",
        },
        spaceBetween: 32,
        slidesPerView: 3,
        speed: 500,
        grabCursor: true,
        breakpoints: {
            0: {
                slidesPerView: 1,
                spaceBetween: 16
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 24
            },
            992: {
                slidesPerView: 3,
                spaceBetween: 32
            }
        }
    });

    new Swiper('#partner-slider', {
        modules: [Navigation, EffectFade],
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        effect: "fade",
        sliderPerView: 1,
        spaceBetween: 0,
        speed: 500,
        grabCursor: true,
    });

    let swiper = new Swiper(".bottomSlider", {
        spaceBetween:0,
        slidesPerView: 6,
        freeMode: true,
        watchSlidesProgress: true,
    });
    let swiper2 = new Swiper("#header-slider", {
        modules: [Navigation,Thumbs],
        spaceBetween: 0,
        grabCursor: true,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        thumbs: {
            swiper: swiper,
        },
    });

    new Swiper('#video-slider', {
        modules: [Navigation],
        navigation: {
            nextEl: ".video-button-next",
            prevEl: ".video-button-prev",
        },
        slidesPerView: 2,
        spaceBetween: 32,
        speed: 800,
        grabCursor: true,
        breakpoints: {
            0: {
                slidesPerView: 1.25,
            },
            768: {
                slidesPerView: 2,
            },
            1200: {
                slidesPerView: 2,
            },
        },
    });
});
