$(function () {
    const els = document.getElementsByClassName('uq-map-element');

    if (els.length > 0) {
        const L = require('leaflet');

        for (let smap of els) {
            let lat = parseFloat(smap.dataset.lat);
            let lng = parseFloat(smap.dataset.lng);
            let zoom = parseFloat(smap.dataset.zoom);
            let pointers = JSON.parse(smap.dataset.pointers);
            let image = smap.dataset.image;
            let icon;

            if (!!image) {
                icon = image;
            } else {
                icon = 'https://cheatsheet.uniqoderslab.com/wp-content/uploads/2021/10/map-icon.png';
            }

            let map_icon = L.icon({
                iconUrl: icon,

                iconSize: [50, 50],
                shadowSize: [64, 64],
                iconAnchor: [25, 25],
                popupAnchor: [-3, -76]
            });

            let map = L.map(smap).setView([lat, lng], zoom);

            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            }).addTo(map);

            if (pointers.length > 0) {
                for (let point in pointers) {
                    let pointerLat = parseFloat(pointers[point]['lat']);
                    let pointerLng = parseFloat(pointers[point]['lng']);

                    L.marker([pointerLat, pointerLng], {icon: map_icon}).addTo(map);
                }
            } else {
                L.marker([lat, lng], {icon: map_icon}).addTo(map);
            }
        }
    }
})