$(document).ready(function () {
    let $list = $("#collapse-list");
    let $list_item = $('.list-group-item');
    let list_item_height = $list_item.outerHeight();
    let list_length = $list.find('li').length;
    let $toggle_collapse = $('#toggle-collapse');

    if ($list.height() > 100) {
        $list.addClass("expand closed");
    }

    $list.css("height", "auto");
    let listheight = $list.css("height");

    if (list_length > 5) {
        $list.css("height", list_item_height * 5 + 'px');
    } else {
        $list.addClass('uq-bordered');
        $toggle_collapse.addClass('d-none');
    }

    $toggle_collapse.on("click", function () {
        let $this = $(this);
        $this.toggleClass('open');
        $list.toggleClass("open closed");

        if ($list.hasClass("open")) {
            $list.height(listheight);
        }
        if ($list.hasClass("closed")) {
            $list.height(list_item_height * 5);
        }
    });
});