import {CountUp} from 'countup.js';
require('waypoints/lib/noframework.waypoints.js')

$(function () {
    const els = document.getElementsByClassName('data-counter');

    for (let el of els) {
        let start = parseFloat(el.dataset.start) ?? 0;
        let endValue = parseFloat(el.dataset.counter);
        let suffix = el.dataset.suffix;

        new Waypoint({
            element: el,
            handler: function () {
                const options = {
                    startVal: start,
                    duration: 3,
                    suffix: suffix
                };

                let countUp = new CountUp(el, endValue, options);

                countUp.start();
                this.destroy();
            },
            offset: 'bottom-in-view',
        })
    }
})